// // utils/incrementViewCount.js
// import axios from 'axios';
// import { API_URL } from '../config';
// import { v4 as uuidv4 } from 'uuid';
// import { getValidToken } from './auth';
// import generateDeviceIdentifier from './deviceIdentifier';

// const getIpAddress = async () => {
//   try {
//     const response = await axios.get('https://api.ipify.org?format=json');
//     return response.data.ip;
//   } catch (error) {
//     console.error('Error getting IP address:', error);
//     return null;
//   }
// };

// const incrementViewCount = async (viewType, componentId, navigate) => {
//   try {
//     const token = await getValidToken(navigate, false);
//     const sessionKey = `viewIncremented_${viewType}_${componentId}`;

//     // Check if the view has already been incremented in this session
//     const hasIncremented = sessionStorage.getItem(sessionKey);
//     if (!hasIncremented) {
//       const uniqueId = uuidv4();
//       const deviceIdentifier = await generateDeviceIdentifier();
//       const ipAddress = await getIpAddress();
//       const apiUrl = token ? `${API_URL}/increment-view` : `${API_URL}/public/increment-view`;

//       const requestOptions = {
//         method: 'POST',
//         url: apiUrl,
//         headers: token ? { Authorization: `Bearer ${token}` } : undefined,
//         withCredentials: true,
//         data: { componentId, viewType, uniqueId, deviceIdentifier, ipAddress },
//       };

//       await axios(requestOptions);
//       sessionStorage.setItem(sessionKey, 'true'); // Mark as incremented for this session only
//     } else {
//       console.log('View already counted in this session');
//     }
//   } catch (error) {
//     console.error('Error incrementing view count:', error);
//   }
// };

// export default incrementViewCount;

import axios from 'axios';
import { API_URL } from '../config';
import { v4 as uuidv4 } from 'uuid';
import { getValidToken } from './auth';
import generateDeviceIdentifier from './deviceIdentifier';

const getIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error getting IP address:', error);
    return null;
  }
};

const incrementViewCount = async (viewType, componentId, navigate) => {
  try {
    const token = await getValidToken(navigate, false);
    const uniqueId = uuidv4();
    const deviceIdentifier = await generateDeviceIdentifier();
    const ipAddress = await getIpAddress();
    const apiUrl = token ? `${API_URL}/increment-view` : `${API_URL}/public/increment-view`;

    const requestOptions = {
      method: 'POST',
      url: apiUrl,
      headers: token ? { Authorization: `Bearer ${token}` } : undefined,
      withCredentials: true,
      data: { componentId, viewType, uniqueId, deviceIdentifier, ipAddress },
    };

    await axios(requestOptions);
    console.log('View incremented and session storage updated');
  } catch (error) {
    console.error('Error incrementing view count:', error);
  }
};

export default incrementViewCount;

