// ReceiptDetails.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth';
import { API_URL, baseURL } from '../../config';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ModalContainer = styled.div`
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // z-index: 1000;
    // width: 80%;
    // max-width: 600px;
    // background: ${props => props.theme.colors.background2};
    // border: 1px solid ${props => props.theme.colors.border};
    // border-radius: 8px;
    // padding: 20px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
     overflow-y: auto;
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const FormGroup = styled.div`
    margin: 15px 0px;
`;

const FormGroupUser = styled.div`
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme.colors.text};
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.text};
`;

const Select = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-size: 16px;
`;

const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`; 

const Button = styled.button`
    background-color: ${props => props.theme.colors.primary};
    color: white;
    width: 100%;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    &:hover {
        background-color: ${props => props.theme.colors.primaryHover};
    }
`;

const DeleteButton = styled(Button)`
    background-color: red;
    &:hover {
        background-color: darkred;
    }
`;

const ReceiptDetails = ({ receipt, onClose, refresh }) => {
    const [status, setStatus] = useState(receipt.status);
    const [message, setMessage] = useState(receipt.message);
    const [isUpdating, setIsUpdating] = useState(false);
    const navigate = useNavigate();

    const handleUpdate = async () => {
        setIsUpdating(true);
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                await axios.post(`${API_URL}/admin/receipts/update`, { id: receipt.id, status, message }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                refresh();
                onClose();
            } catch (error) {
                console.error('Error updating receipt:', error);
            } finally {
                setIsUpdating(false);
            }
        }
    };

    const handleDelete = async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                await axios.delete(`${API_URL}/admin/receipts/${receipt.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                refresh();
                onClose();
            } catch (error) {
                console.error('Error deleting receipt:', error);
            }
        }
    };

    return (
        <>
            <ModalContainer>
                <h2>Receipt Details</h2>
                <FormGroupUser><p>{receipt.username}</p> <p>{receipt.phone}</p></FormGroupUser>
                <FormGroup>
                <img src={`${baseURL}${receipt.path}`} alt='Receipt' width={'100%'} height={'100%'} />
                </FormGroup>
                <FormGroup>
                {receipt.code && <p>{receipt.code}</p>}
                </FormGroup>
                <FormGroup>
                    <Label>Status</Label>
                    <Select value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="waiting">Waiting</option>
                        <option value="rejected">Rejected</option>
                    </Select>
                </FormGroup>
                <FormGroup>
                    <Label>Message</Label>
                    <TextArea 
                    rows="5" 
                    value={message} 
                    onChange={e => setMessage(e.target.value)} 
                    />
                </FormGroup>
                <ButtonsDiv>
                <Button onClick={handleUpdate} disabled={isUpdating}>
                    {isUpdating ? 'Updating...' : 'Update'}
                </Button>
                <DeleteButton onClick={handleDelete}>
                    Delete
                </DeleteButton>
                <Button onClick={onClose}>
                    Close
                </Button>
                </ButtonsDiv>
            </ModalContainer>
        </>
    );
};

export default ReceiptDetails;
