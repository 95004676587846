// components/ConsentBanner.js
import React, { useState } from 'react';

const ConsentBanner = () => {
  const [consentGiven, setConsentGiven] = useState(localStorage.getItem('consentGiven') === 'true');

  const handleConsent = () => {
    localStorage.setItem('consentGiven', 'true');
    setConsentGiven(true);
  };

  if (consentGiven) {
    return null;
  }

  return (
    <div className="consent-banner">
      <p>We use cookies and other methods to collect data about your device to provide a better experience. By using our site, you consent to our privacy policy.</p>
      <button onClick={handleConsent}>I Agree</button>
    </div>
  );
};

export default ConsentBanner;
