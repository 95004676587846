import React, { useState } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth'; // Adjust the import as necessary
import { API_URL } from '../../config'; // Adjust the import as necessary
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingIcon, SuccessIcon, ErrorIcon } from './styles';

const FormField = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-size: 16px;
`;

const Select = styled.select`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    font-size: 16px;
`;

const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`; 

const Button = styled.button`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    &:disabled {
        background: #aaa;
        cursor: not-allowed;
    }
`;

const UserEditForm = ({ user, onClose, refresh, refreshCounts }) => {
    const [username, setUsername] = useState(user.username);
    const [email, setEmail] = useState(user.email);
    const [role, setRole] = useState(user.role_id);
    const [status, setStatus] = useState(user.active);
    const [isLoading, setIsLoading] = useState(false);
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setLoginSuccess(false);
        setError('');
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                await axios.post(`${API_URL}/users/update`, { id: user.id, username, email, role, status }, config);
    
                setError('');
                setLoginSuccess(true);
                setIsLoading(false);

                setTimeout(() => {
                    setLoginSuccess(false);
                    onClose();
                    refresh();
                    refreshCounts();
                }, 2000); 
            } catch (error) {
                console.error('Error updating user:', error);
                setError(error.message);
                setIsLoading(false);
                setTimeout(() => {
                    setError('');
                }, 4000);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormField>
                <Label>Username</Label>
                <Input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Email</Label>
                <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormField>
            <FormField>
                <Label>Role</Label>
                <Select value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value={1}>Admin</option>
                    <option value={2}>User</option>
                    <option value={3}>Producer</option>
                </Select>
            </FormField>
            <FormField>
                <Label>Status</Label>
                <Select value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value={1}>Active</option>
                    <option value={0}>InActive</option>
                    <option value={2}>Blocked</option>
                </Select>
            </FormField>

            <ButtonsDiv>
            <Button onClick={handleSubmit} disabled={isLoading || loginSuccess}>
                
                {loginSuccess ? <SuccessIcon /> : error ? <ErrorIcon /> : 'Update Status'}
                {isLoading && <LoadingIcon />}
            </Button>
            <Button onClick={onClose} style={{ marginTop: '10px', background: 'red' }}>Close</Button>
            </ButtonsDiv>
        </form>
    );
};

export default UserEditForm;
