import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_URL } from '../../config';
import { Card, Close, StyledButton, InputBox, StyledInput, Label} from '../Admin/EditUser.styles';

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Heading = styled.h2`
  margin-bottom: 20px;
`;

// const Input = styled.input`
//   padding: 8px;
//   margin-bottom: 10px;
// `;

const TextArea = styled.textarea`
  padding: 8px;
  margin-bottom: 10px;
`;

// const Button = styled.button`
//   padding: 10px 20px;
//   margin-right: 10px;
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// `;

const AdminWalletManagement = () => {
  const [userData, setUserData] = useState({ emailOrPhone: '', action: 'credit', amount: '', reason: '' });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdateWallet = async (e) => {
    e.preventDefault();
    try {
        const token = localStorage.getItem('jwtToken');
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(`${API_URL}/admin/wallet-management`, userData, config);
      setSuccessMessage(response.data.message);
      setError('');
      setUserData({ emailOrPhone: '', action: 'credit', amount: '', reason: '' });
    } catch (error) {
      console.error('Error updating wallet:', error);
      setError(error.response?.data?.error || 'An error occurred.');
      setSuccessMessage('');
    }
  };

  return (
    <Container>
      <Heading>Admin Wallet Management</Heading>

      <InputBox>
      <StyledInput
        type="text"
        name="emailOrPhone"
        placeholder="User Email or Phone"
        value={userData.emailOrPhone}
        onChange={handleInputChange}
      />
      <Label>User Email or Phone</Label>
      </InputBox>

      <InputBox>
      <select name="action" value={userData.action} onChange={handleInputChange}>
        <option value="credit">Credit</option>
        <option value="debit">Debit</option>
      </select>
      </InputBox>

      <InputBox>
      <StyledInput
        type="number"
        name="amount"
        placeholder="Amount"
        value={userData.amount}
        onChange={handleInputChange}
      />
      <Label>Amount</Label>
      </InputBox>

      <TextArea
        name="reason"
        placeholder="Reason"
        value={userData.reason}
        onChange={handleInputChange}
      />

      <StyledButton onClick={handleUpdateWallet}>Update Wallet</StyledButton>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
    </Container>
  );
};

export default AdminWalletManagement;