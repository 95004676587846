import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { registerSuccess } from '../reducers/actions';
import { useTranslation } from 'react-i18next';
//import { sanitizeInput } from './utils';
import DOMPurify from 'dompurify';
import TermsAndConditionsSignup from './TermsAndConditionsSignup';
import arewanAudio from '../assets/arewan.ogg';
// import errorAudio from '../assets/arewan3.ogg';
// import errorAudio1 from '../assets/arewan3.ogg';
// import errorAudio2 from '../assets/arewan3.ogg';
// import errorAudio3 from '../assets/arewan3.ogg';
import TutorialVideoModel from '../components/TutorialVideoModel';
import { getRandomColor } from '../utils/getRandomColor';

import { IoMdCloseCircle } from "react-icons/io";
import { Container, OuterDiv, CircleContainer } from './Register.styles';
import Spinner from '../components/Spinner';

import { AudioIndicator, PlayAudioButton, PauseAudioButton, Close, LoadingIcon, SuccessIcon, ErrorIcon, TitleContainer, Title, Card, InputBox, StyledInput, Label, Icon, StyledButton, StyledLink } from './PublicStyles'; // Import Card and FormControl directly
import { FaUser, FaLock, FaPhone, FaRegUserCircle, FaUserEdit } from 'react-icons/fa';
import {CheckboxContainer, Checkbox, TermsLink, FormModelDiv, PtagDiv, ModalBackdrop} from './BecomeCreatorForm';
import { MdEmail } from 'react-icons/md';
import { BiMoviePlay } from "react-icons/bi";
import { API_URL } from '../config';
//import { RxAvatar } from "react-icons/rx";


const Register = () => {
  const [screenLoading, setScreenLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [audioSrc, setAudioSrc] = useState(arewanAudio);
  const [errorAudioSrc, setErrorAudioSrc] = useState('');
  const [registerSuccessfull, setRegisterSuccessfull] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);

  // const audioRef = useRef(null);
  // const initialAudioSrcRef = useRef(arewanAudio);
  // const [isPlaying, setIsPlaying] = useState(false);

  const [previewImage, setPreviewImage] = useState(null);

  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleShowModal = () => {
    setShowVideoModal(true);
  };

  const handleModalClose = () => {
    setShowVideoModal(false);
    navigate('/register'); // Navigate back to the register component
  };

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useHistory



  useEffect(() => {
    // Simulate page content rendering
    const timeout = setTimeout(() => {
      setScreenLoading(false); // Hide loading spinner after 2 seconds (adjust timing as needed)
    }, 2000);

    return () => clearTimeout(timeout);
  }, []); // Empty dependency array means this effect runs once after component mounts

  // useEffect(() => {
  //   const currentAudio = audioRef.current;

  //   // const handleCanPlay = () => {
  //   //   if (currentAudio) {
  //   //     currentAudio.play();
  //   //     setIsPlaying(true);
  //   //   }
  //   // };

  //   const handleEnded = () => {
  //     setAudioSrc(initialAudioSrcRef.current);
  //     setIsPlaying(false);
  //   };

  //   const handleError = () => {
  //     console.error('Error loading audio.');
  //   };

  //   if (currentAudio) {
  //   //  currentAudio.addEventListener('canplay', handleCanPlay);
  //     currentAudio.addEventListener('ended', handleEnded);
  //     currentAudio.addEventListener('error', handleError);

  //     // Clean up event listeners
  //     return () => {
  //   //    currentAudio.removeEventListener('canplay', handleCanPlay);
  //       currentAudio.removeEventListener('ended', handleEnded);
  //       currentAudio.removeEventListener('error', handleError);
  //     };
  //   }
  // }, [audioSrc, errorAudioSrc]); 

  // console.log('Audio Src', audioSrc);
  // console.log('Error Audio ', errorAudioSrc);

  // const handlePlay = () => {
  //   if (audioRef.current) {
  //     audioRef.current.play();
  //     setIsPlaying(true);
  //   }
  // };

  // const handlePause = () => {
  //   if (audioRef.current) {
  //     audioRef.current.pause();
  //     setIsPlaying(false);
  //   }
  // };

  // const handleAudioChange = (newSrc) => {
  //   const currentAudio = audioRef.current;
  //   if (currentAudio) {
  //     currentAudio.pause();
  //     currentAudio.src = newSrc;
  //     currentAudio.load();
  //     // currentAudio.play();
  //     // setIsPlaying(true);
  //     setAudioSrc(newSrc);
  //   }
  // };
  
  

  const [step1FormData, setStep1FormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });

  const [step2FormData, setStep2FormData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    profilePicture: '', 
    referralCode: '',
  });

  const { firstName, lastName, phone, email } = step1FormData;
  const { username, password, confirmPassword, profilePicture, referralCode } = step2FormData;

  const onChangeStep1 = e => {
    const { name, value } = e.target;
    setStep1FormData({ ...step1FormData, [name]: value });
  };

  const onChangeStep2 = (e) => {
    const { name, value, type, files } = e.target;
  
    if (type === 'file') {
      // Handle file input
      const file = files[0]; // Assuming single file upload
      setStep2FormData({ ...step2FormData, [name]: file });
      // Optionally, you can also update the preview image state here
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Handle text inputs
      setStep2FormData({ ...step2FormData, [name]: value });
    }
  };
  

  const onSubmitStep1 = async e => {
    e.preventDefault();
    if (!firstName || !lastName || !phone || !email) {
      const error = t('errors.all fields are required.');
      setError(error);
      return;
    }

    setError('');
    setStep(2); // Move to step 2
  };

  const onSubmitStep2 = async e => {
    e.preventDefault();
    if (!username || !password || !confirmPassword) {
      const error = t('errors.all fields are required.');
      setError(error);
      setLoading(false);
  
        setTimeout(() => {
          setError(null);
        }, 8000); 
      return;
    }

    if (password !== confirmPassword) {
      const error = t('errors.passwords do not match.');
      setError(error);
      setLoading(false);
  
        setTimeout(() => {
          setError(null);
        }, 8000); 
      return;
    }

    if (password.length < 8) {
      const error = t('errors.password must be at least 8 characters long.');
      setError(error);
      setLoading(false);
  
        setTimeout(() => {
          setError(null);
        }, 8000); 
      return;
    }

    setError('');
    onSubmitRegistration();
  };


  const onSubmitRegistration = async () => {
    setLoading(true);

    if (!termsAccepted) {
      setError('You must accept the terms and conditions');
      setLoading(false);
      setTimeout(() => {
          setError('');
      }, 7000);
      return;
    }

    try {
      const sanitizedFirstName = DOMPurify.sanitize(firstName);
      const sanitizedLastName = DOMPurify.sanitize(lastName);
      const sanitizedEmail = DOMPurify.sanitize(email);
      const sanitizedPhone = DOMPurify.sanitize(phone);
      const sanitizedUsername = DOMPurify.sanitize(username);
      const sanitizedPassword = DOMPurify.sanitize(password);
      const sanitizedReferrelCode = DOMPurify.sanitize(referralCode);

      // Generate random color if profile picture is not provided
      const avatarColor = profilePicture ? '' : getRandomColor();

      const formData = new FormData();
      formData.append('firstName', sanitizedFirstName);
      formData.append('lastName', sanitizedLastName);
      formData.append('email', sanitizedEmail);
      formData.append('phone', sanitizedPhone);
      formData.append('username', sanitizedUsername);
      formData.append('password', sanitizedPassword);
      formData.append('profilePicture', profilePicture); // Append the profile picture here
      formData.append('avatarColor', avatarColor); // Append the generated avatar color
      formData.append('referralCode', sanitizedReferrelCode);
  
      // Send registration request with FormData containing all form data
      const res = await axios.post(`${API_URL}/users/register`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set content type for file uploads
        },
      });
  
      dispatch(registerSuccess(email)); // Store email in Redux
  
      if (res.status === 200) {
        setRegisterSuccessfull(true);
        setLoading(false);
  
        setTimeout(() => {
          setRegisterSuccessfull(false);
          navigate('/login');
        }, 2000); 
      }
    } catch (err) {
      // Handle errors
      if (err.response) {
        // Server responded with an error
        console.error('Server Error:', err.response.data);
        let errorMessage = '';

        if (err.response.data.msg === 'Email already exists') {
          errorMessage = t('errors.emailExists'); // Translate email exists error
        } else if (err.response.data.msg === 'Phone already exists') {
          errorMessage = t('errors.phoneExists'); 
        } else if (err.response.data.msg === 'Duplicate entry error') {
          errorMessage = t('errors.duplicateEntry'); 
        } else {
          errorMessage = t('errors.serverError'); // Default server error message
        }

        setError(errorMessage);
      } else if (err.message === 'Server Error') {
        // Network error, backend is offline
        console.error('Network Error:', err.message);
        const errorMessage = t('errors.networkError'); 
        console.log('Error Message:', errorMessage);
        setError(errorMessage);
      } else {
        // Other unexpected errors
        console.error('Unexpected Error:', err);
        const errorMessage = t('errors.unexpectedError'); 
        setError(errorMessage);
      }

      setLoading(false);
      setTimeout(() => {
        setError(null);
      }, 8000);
    }
  };

  const handleTermsToggle = () => {
        setTermsVisible(!termsVisible);
};

const handleIagreeChange = () => {
  setTermsAccepted(!termsAccepted);
}
  

  return (
    <Container>
      {screenLoading ? (
        <Spinner />
      ) : (
        <Card>

        <Close onClick={() => navigate('/')}>
      <IoMdCloseCircle style={{ fontSize: '34px', fontWeight: '500' }} />
      </Close>

        
        
        {!error && (
          <>
          <PlayAudioButton onClick={handleShowModal}>
          {t('play audio')} <AudioIndicator showVideoModal={showVideoModal}></AudioIndicator> 
          <BiMoviePlay style={{ color: 'red', fontSize: '18px' }} />
          </PlayAudioButton>
        
        {showVideoModal && (
          <TutorialVideoModel
            videoId="UXFhm_V_cUg"
            onClose={handleModalClose}
          />
        )}
          {/* <audio ref={audioRef} src={audioSrc} autoPlay={false}>
        </audio>
        <div style={{ display: 'flex', gap: '5px', justifyContent: 'center', alignContent: 'center' }}>
        <PlayAudioButton onClick={handlePlay} disabled={isPlaying}>
          {t('play audio')} <AudioIndicator isPlaying={isPlaying}></AudioIndicator>
        </PlayAudioButton>
        <PauseAudioButton onClick={handlePause} disabled={!isPlaying}>
        {t('pause audio')}
        </PauseAudioButton>
      </div> */}
        </>
       )}

          <div style={{ margin: '3px', maxWidth: '355px' }}>
            <TitleContainer>
              <Title>{t('register')}</Title>
            </TitleContainer>
            {/* Display error or success message if registration fails */}
            {registerSuccessfull && <p style={{ color: 'green', backgroundColor: 'black', padding: '5px' }}>{t('registration successful!')}</p>}
            {error && (
              <>
              {/* <audio ref={audioRef} src={errorAudioSrc} autoPlay={false}>
              </audio>
              <div style={{ display: 'flex', gap: '5px', justifyContent: 'center', alignContent: 'center' }}>
              <PlayAudioButton onClick={handlePlay} disabled={isPlaying}>
                Play Error <AudioIndicator isPlaying={isPlaying}></AudioIndicator>
              </PlayAudioButton>
              <PauseAudioButton onClick={handlePause} disabled={!isPlaying}>
                Pause Error
              </PauseAudioButton>
            </div> */}
             <p style={{ color: 'red', maxWidth: '355px', backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', margin: '0', padding: '5px' }}>{error}</p>
             </>
             )}
            {step === 1 && (
              <form onSubmit={onSubmitStep1}>
                <InputBox>
                  <Icon>
                    <FaUser style={{ color: 'black', fontSize: '24px' }} />
                  </Icon>
                  <StyledInput
                    type="text"
                  //  placeholder="First Name"
                    name="firstName"
                    value={firstName}
                    onChange={onChangeStep1}
                    required
                  />
                  <Label>{t('first name')}</Label>
                </InputBox>

                <InputBox>
                  <Icon>
                    <FaUser style={{ color: 'black', fontSize: '24px' }} />
                  </Icon>
                  <StyledInput
                    type="text"
                  //  placeholder="Last Name"
                    name="lastName"
                    value={lastName}
                    onChange={onChangeStep1}
                    required
                  />
                  <Label>{t('last name')}</Label>
                </InputBox>

                <InputBox>
                  <Icon>
                    <FaPhone style={{ color: 'black', fontSize: '24px' }} />
                  </Icon>
                  <StyledInput
                    type="text"
                  //  placeholder="Phone Number"
                    name="phone"
                    value={phone}
                    onChange={onChangeStep1}
                    required
                  />
                  <Label>{t('phone number')}</Label>
                </InputBox>

                <InputBox>
                  <Icon>
                    <MdEmail style={{ color: 'black', fontSize: '24px' }} />
                  </Icon>
                  <StyledInput
                    type="email"
                  //  placeholder="Email Address"
                    name="email"
                    value={email}
                    onChange={onChangeStep1}
                    required
                  />
                  <Label>{t('email')}</Label>
                </InputBox>

                <StyledButton type="submit" disabled={loading}>
                {t('continue')}
                  {loading && <LoadingIcon />}
                </StyledButton>
              </form>
            )}

            {step === 2 && (
              <form onSubmit={onSubmitStep2}>
                {/* `${t('choose Image')}` */}
                <OuterDiv>
                <CircleContainer>
                <label htmlFor="profile-picture-input">
                  {previewImage ? <img src={previewImage} alt="Preview" /> : <FaRegUserCircle style={{ width: '100px', height: '100px', color: 'black', fontSize: '24px' }} /> }  
                </label>
                <input
                  id="profile-picture-input" // Added id to link with the label
                  type="file"
                  name="profilePicture" 
                  accept="image/*"
                  onChange={onChangeStep2}
                />
                
              </CircleContainer>
              {/* <span className="icon" role="img" aria-label="Pen Icon">
                  ✏️
                </span> */}
              </OuterDiv>

                <InputBox>
                  <Icon>
                    <FaUser style={{ color: 'black', fontSize: '24px' }} />
                  </Icon>
                  <StyledInput
                    type="text"
                  //  placeholder="Username"
                    name="username"
                    value={username}
                    onChange={onChangeStep2}
                    required
                  />
                  <Label>{t('username')}</Label>
                </InputBox>

                <InputBox>
                  <Icon>
                    <FaLock style={{ color: 'black', fontSize: '24px' }} />
                  </Icon>
                  <StyledInput
                    type="password"
                  //  placeholder="Password"
                    name="password"
                    value={password}
                    onChange={onChangeStep2}
                    required
                  />
                  <Label>{t('password')}</Label>
                </InputBox>

                <InputBox>
                  <Icon>
                    <FaLock style={{ color: 'black', fontSize: '24px' }} />
                  </Icon>
                  <StyledInput
                    type="password"
                  //  placeholder="Confirm Password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={onChangeStep2}
                    required
                  />
                  <Label>{t('confirm password')}</Label>
                </InputBox>

                <InputBox>
                  <Icon>
                    <FaLock style={{ color: 'black', fontSize: '24px' }} />
                  </Icon>
                  <StyledInput 
                    type="text"
                    name="referralCode" 
                    //placeholder="Referral Code (optional)" 
                    onChange={onChangeStep2} 
                    />
                  <Label>{t('referral code (optional)')}</Label>
                </InputBox>

                {error && <PtagDiv><p>{error}</p></PtagDiv>}

                <CheckboxContainer>
                    <Checkbox 
                        type="checkbox" 
                        checked={termsAccepted} 
                        onChange={handleIagreeChange}
                        disabled={loading} 
                    />
                    <TermsLink onClick={handleTermsToggle}>Terms and Conditions</TermsLink>
                </CheckboxContainer>

                {termsVisible && (
                    <>
                    <ModalBackdrop onClick={handleTermsToggle} />
                    <FormModelDiv>
                    <TermsAndConditionsSignup onClose={handleTermsToggle} iAgree={handleIagreeChange} />
                    </FormModelDiv>
                    </>
                )}

                <div style={{ maxWidth: '355px' }}>
                <StyledButton type="submit" disabled={loading || registerSuccessfull}>
                  {registerSuccessfull ? <SuccessIcon /> : error ? <ErrorIcon /> : `${t('register button')}`}
                  {loading && <LoadingIcon />}
                </StyledButton>
                </div>
              </form>
            )}

            <div style={{ marginTop: '10px', marginBottom: '8px', textAlign: 'center' }}>
              <StyledLink to="/login">{t('already have an account? login')}</StyledLink>
            </div>
          </div>
        </Card>
      )}
    </Container>
  );
};

export default Register;