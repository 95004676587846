import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth'; 
import { API_URL, baseURL } from '../../config';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import UserEditForm from './UserEditForm'; 
import useDebounce from '../../utils/useDebounce';
import Spinner from '../Spinner';
import { Card, ThumbnailDiv, PostDetailsDiv, PostTitle, AudioIndicator, ActiveStatusAndEllipsesDiv, Actions, AdminActiveStatusDiv, StatusDiv, StyledP, 
  StyledTime, Ellipses, DropdownHeader, DropdownMenu, DropdownOption, CloseIcon, PostAnalysis } from '../ProducerAllPosts';
import { MdClose } from 'react-icons/md';
import { IoMdOptions } from "react-icons/io";
const FormContainer = styled.div`
    position: relative;
    max-width: 800px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
    color: ${props => props.theme.colors.text};
    border: 1px solid #ddd;
    border-radius: 8px;
    background: ${props => props.theme.colors.background2};
`;

const SpinnerContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 20px;
    color: ${props => props.theme.colors.text};
    border: 1px solid #ddd;
    border-radius: 8px;
    background: ${props => props.theme.colors.background2};
`;

const FormModelDiv = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 90%;
    max-width: 800px;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    background: ${props => props.theme.colors.background2};
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
`;

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const FormTitle = styled.h2`
    text-align: center;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.text};
`;

const Tabs = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    border-radius: 10px;
`;

const Tab = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    background: ${props => props.active ? props.theme.colors.primary : '#ddd'};
    color: ${props => props.active ? 'white' : 'black'};
    cursor: pointer;
    &:hover {
        background: #007bff;
        color: white;
    }
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
`;

const UserList = styled.div`
    margin-bottom: 20px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

const Pagination = styled.div`
  display: flex;
  gap: 10px;

  .active {
    color: #28a745;
    font-weight: bold;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const PageNumber = styled.div`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #e0e0e0;
    text-decoration: underline;
  }

  &.active {
    color: #28a745;
    background-color: #e8ffe8;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;

const PaginationButton = styled.button`
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  flex-shrink: 0;
  font-size: 12px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 8px 12px;
    margin: 0 4px;
  }
`;


const Users = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [counts, setCounts] = useState({ allUsers: 0, authors: 0, blockedUsers: 0 });
    const [tab, setTab] = useState('all');
    const [dropdownPostId, setDropdownPostId] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const ListRef = useRef(null);
    const navigate = useNavigate();

    const debouncedSearch = useDebounce(search, 500); // 500ms debounce delay

    

    const fetchUsers = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            setIsLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/users/getAll`, {
                    params: {
                        page: currentPage,
                        limit: 10,
                        search: debouncedSearch,
                        tab,
                    },
                    ...config
                });
                setUsers(response.data.users);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentPage, debouncedSearch, tab, navigate]);

    const fetchCounts = useCallback(async () => {
      const token = await getValidToken(navigate, true);
      if (token) {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          };
          const response = await axios.get(`${API_URL}/users/counts`, {
            params: { search: debouncedSearch, },
            ...config
          });
          setCounts(response.data);
        } catch (error) {
          console.error('Error fetching user counts:', error);
        }
      }
    }, [debouncedSearch, navigate]);

    useEffect(() => {
      fetchCounts();
      fetchUsers();
  }, [currentPage, debouncedSearch, tab, navigate, fetchUsers, fetchCounts]);

  const handleDelete = async (userId) => {
    const token = await getValidToken(navigate, true); 
      if (token) {
    if (window.confirm('Are you sure you want to delete this ad?')) {
      try {
        await axios.delete(`${API_URL}/admin/ad/delete/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }); 
        setUsers(users.filter(user => user.id !== userId)); 
        setIsDropdownOpen(false);
      } catch (error) {
        setIsDropdownOpen(false);
        console.error('Error deleting ad:', error);
      }
    }
  }
  };

    const handleSelectUser = (user) => {
        if (selectedUser && selectedUser.id === user.id) {
            setSelectedUser(null);
        } else {
            setSelectedUser(user);
        }
    };

    const handleCloseUser = () => {
        setSelectedUser(null);
    };

    const handleDropdownClick = (adId) => {
      if (dropdownPostId === adId && isDropdownOpen) {
        setIsDropdownOpen(false);
        setDropdownPostId(null);
      } else {
        setIsDropdownOpen(true);
        setDropdownPostId(adId);
      }
    };

    const handleUpdatePostStatus = (adId) => {
      setDropdownPostId(null);
      setIsDropdownOpen(false);
    };
  
    const handleCloseDropdown = () => {
      setDropdownPostId(null);
      setIsDropdownOpen(false);
    };

    const scrollToTop = () => {
      ListRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const handlePageClick = (page) => {
      setCurrentPage(page);
      scrollToTop();
  };

  const handleNextPage = () => {
      if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
          scrollToTop();
      }
  };

  const handlePreviousPage = () => {
      if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          scrollToTop();
      }
  };

  const handleTabChange = (tab) => {
      setTab(tab);
      setCurrentPage(1);
  };

  const formatTimeAgo = (createdDate, scheduledAt = null) => {
    const currentDate = new Date();
  
    // Check if the post is scheduled for the future
  if (scheduledAt) {
    const scheduledDate = new Date(scheduledAt);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    if (scheduledDate > currentDate) {
      return `Will be posted on ${scheduledDate.toLocaleString('en-US', options)}`;
    }
  }

  
    const timeDifference = currentDate - new Date(createdDate);
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(monthsDifference / 12);
  
    if (yearsDifference > 0) {
      return `${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
    } else if (monthsDifference > 0) {
      return `${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
    } else if (daysDifference > 0) {
      return `${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
    } else if (hoursDifference > 0) {
      return `${hoursDifference} ${hoursDifference === 1 ? 'hour' : 'hours'} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} ${minutesDifference === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return `${secondsDifference} ${secondsDifference === 1 ? 'second' : 'seconds'} ago`;
    }
  };
  

  const getDisplayStatus = (status, scheduledAt) => {
    const now = new Date();
    const scheduledDate = new Date(scheduledAt);
  
    // If the post is scheduled and the scheduled date is in the past
    if (status === 'scheduled' && scheduledDate <= now) {
      return 'public';
    }
  
    // Otherwise return the original status
    return status;
  };

    if (isLoading) {
        return (
            <SpinnerContainer>
                <Spinner />
            </SpinnerContainer>
        );
    }

    return (
        <FormContainer>
            <FormTitle>All Users</FormTitle>
            <Tabs>
              <Tab active={tab === 'all'} onClick={() => handleTabChange('all')}>
                All Users ({counts.allUsers})
              </Tab>
              <Tab active={tab === 'authors'} onClick={() => handleTabChange('authors')}>
                Authors ({counts.authors})
              </Tab>
              {/* <Tab active={tab === 'all'} onClick={() => handleTabChange('all')}>
                In Active ({counts.allUsers})
              </Tab> */}
              <Tab active={tab === 'blocked'} onClick={() => handleTabChange('blocked')}>
                Blocked Users ({counts.blockedUsers})
              </Tab>
            </Tabs>

            <SearchInput
                type="text"
                placeholder="Search by username or email"
                value={search}
                onChange={handleSearchChange}
            />
            <UserList ref={ListRef}>
                {users.map(user => (
                  <Card key={user.id}>
                  <ThumbnailDiv onClick={() => handleSelectUser(user)}>
                      {user.profile_picture && <img src={`${baseURL}${user.profile_picture}`} alt="Thumbnail" />}
                  </ThumbnailDiv>
                  <PostDetailsDiv>
                      <PostTitle>Username: {user.username}</PostTitle>
                  <PostAnalysis style={{display: 'flex', flexDirection: 'column'}}>
                      <StyledP>Wallet: N{user.wallet}</StyledP>
                      <StyledP>Earnings: N{user.wallet}</StyledP>
                      <StyledP>Role: {user.role_id}</StyledP>
                      <StatusDiv status={getDisplayStatus(user.active, user.scheduled_at)}>{getDisplayStatus(user.status, user.scheduled_at)}</StatusDiv>
                      <StyledP>{user.phone}</StyledP>
                      <StyledTime>Registred On: {formatTimeAgo(user.created_at, user.scheduled_at)}</StyledTime>
                  </PostAnalysis>
                  <ActiveStatusAndEllipsesDiv>
                      <AdminActiveStatusDiv adminStatus={user.active_status}>
                      <p>{user.active_status}</p> 
                      {user.active_status === 'active' && <AudioIndicator postIsActive={user.active_status}/>}
                      </AdminActiveStatusDiv>
                      <Ellipses onClick={() => handleDropdownClick(user.id)}>
                      <IoMdOptions />
                      </Ellipses>
                  </ActiveStatusAndEllipsesDiv>
                  </PostDetailsDiv>
                  <Actions ref={dropdownRef}>
                  {isDropdownOpen && dropdownPostId === user.id ? (
                      <DropdownMenu onClick={(e) => e.stopPropagation()}>
                      <DropdownHeader>
                          <CloseIcon onClick={handleCloseDropdown}><MdClose /></CloseIcon>
                      </DropdownHeader>
                      <DropdownOption onClick={() => handleSelectUser(user)}>Edit</DropdownOption>
                      <DropdownOption onClick={() => handleDelete(user.id)}>Delete</DropdownOption>
                      <DropdownOption onClick={() => handleUpdatePostStatus(user.id)}>Update Status</DropdownOption>
                      </DropdownMenu>
                  ) : null}
                  </Actions>
                  </Card>
                ))}
            </UserList>
            {totalPages > 1 && (
                <PaginationWrapper>
                    <Pagination>
                        <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Previous
                        </PaginationButton>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <PageNumber
                                key={index + 1}
                                onClick={() => handlePageClick(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                            >
                                {index + 1}
                            </PageNumber>
                        ))}
                        <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                            Next
                        </PaginationButton>
                    </Pagination>
                </PaginationWrapper>
            )}
            {selectedUser && (
                <>
                    <ModalBackdrop onClick={handleCloseUser} />
                    <FormModelDiv>
                        <UserEditForm
                            user={selectedUser}
                            onClose={handleCloseUser}
                            refresh={fetchUsers}
                            refreshCounts={fetchCounts}
                        />
                    </FormModelDiv>
                </>
            )}
        </FormContainer>
    );
};

export default Users;
