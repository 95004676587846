import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { getValidToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ShowStatusModel from './ShowStatusModel';

const ModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${props => props.theme.colors.background};
  border-top: 1px solid ${props => props.theme.colors.text};
  border-radius: 6px;
  padding: 20px;
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.text};
  font-size: 24px;
  margin-bottom: 20px;
`;

// Styled Components
const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};
  option {
    color: ${props => props.theme.colors.text};
  }
`;

const Option = styled.option`
  color: ${props => props.theme.colors.text};
`;

const Button = styled.button`
  background-color: ${props => props.type === 'primary' ? '#4caf50' : '#f44336'};
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;

  &:hover {
    background-color: ${props => props.type === 'primary' ? '#45a049' : '#d32f2f'};
  }
`;

// const ErrorMessage = styled.p`
//   color: red;
//   margin-top: 10px;
// `;

const CommentsModalDiv = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

const SubscriptionModal = ({ authorId, authorUsername, onClose }) => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${API_URL}/public/subscriptions/plans/${authorId}`);
        setPlans(response.data);
        setError('');
        setShowModal(false);
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
        setError('Error fetching subscription plans');
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          setError('');
        }, 4000);
      }
    };
    fetchPlans();

    // if (authorId) {
    //   fetchPlans();
    // }
  }, [authorId]);

  const handleSubscription = async () => {
    if (!selectedPlan) {
      setError('Please select a subscription plan');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        setError('');
      }, 4000);
      return;
    }

    const token = await getValidToken(navigate, true);
    if (token) {
      try {
        const response = await axios.post(`${API_URL}/subscribe`, {
          authorId,
          duration: selectedPlan
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          onClose();
        } else {
          setError(response.data.error || 'Subscription failed');
        }
      } catch (error) {
        console.error('Error subscribing:', error.response?.data || error.message);
        setShowModal(true);
        setError(error.response?.data?.error || 'Subscription failed. Please try again later.');
        setTimeout(() => {
            setShowModal(false);
            setError('');
          }, 4000);
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError('');
  };

  return (
    <ModalContainer>
      <Title>Subscribe to {authorUsername}</Title>
      <div>
        <h3>Select Subscription Plan</h3>
        {/* {error && <ErrorMessage>{error}</ErrorMessage>} */}
        <Select value={selectedPlan} onChange={e => setSelectedPlan(e.target.value)}>
        <Option disabled>Select a plan</Option>
        {plans.map(plan => (
          <Option key={plan.id} value={plan.duration}>
            {plan.name} - N{plan.price} ({plan.duration})
          </Option>
        ))}
      </Select>
      </div>

      <Button onClick={onClose}>Cancel</Button>
      <Button type="primary" onClick={handleSubscription}>Subscribe</Button>

      {showModal && (
        <CommentsModalDiv>
          <ShowStatusModel message={error} onClose={handleCloseModal} />
        </CommentsModalDiv>
      )}
    </ModalContainer>
  );
};

export default SubscriptionModal;



