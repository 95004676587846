import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { getValidToken } from '../../utils/auth';
import { API_URL, baseURL } from '../../config';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import useDebounce from '../../utils/useDebounce';
import ReceiptDetails from './ReceiptDetails';

import {PaginationWrapper, Pagination, PageNumber, PaginationButton, FormContainer, FormTitle, Tabs, Tab, SearchInput, RequestList, RequestItem, FormModelDiv, ModalBackdrop } from './BecomeCreatorFormRequests'


const ForeignTransferReceipts = () => {
    const [receipts, setReceipts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedReceipt, setSelectedReceipt] = useState(null); 
    const [showDetails, setShowDetails] = useState(false); 
    const [tab, setTab] = useState('all');
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [counts, setCounts] = useState({ allRequests: 0, approvedRequests: 0, pendingRequests: 0, waitingRequests: 0, rejectedRequests: 0 });
    const ListRef = useRef(null);
    const navigate = useNavigate();

    const debouncedSearch = useDebounce(search, 500);

    const fetchCounts = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/admin/receipts/counts`, {
                    params: { search: debouncedSearch, tab },
                    ...config
                });
                setCounts(response.data);
            } catch (error) {
                console.error('Error fetching request counts:', error);
            }
        }
    }, [debouncedSearch, tab, navigate]);

    const fetchReceipts = useCallback(async () => {
        const token = await getValidToken(navigate, true);
        if (token) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    withCredentials: true,
                };
                const response = await axios.get(`${API_URL}/admin/receipts`, {
                    params: {
                        page: currentPage,
                        limit: 10,
                        search: debouncedSearch,
                        tab,
                    },
                    ...config
                });
                setReceipts(response.data.receipts || []);
                setTotalPages(response.data.totalPages);
            } catch (error) {
                console.error('Error fetching receipts:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [currentPage, navigate, debouncedSearch, tab]);

    useEffect(() => {
        fetchCounts();
        fetchReceipts();
    }, [currentPage, debouncedSearch, tab, navigate, fetchCounts, fetchReceipts]);

    

    const handleSelectReceipt = (receipt) => {
        setSelectedReceipt(receipt);
        setShowDetails(true);
    };

    const handleCloseDetails = () => {
        setSelectedReceipt(null);
        setShowDetails(false);
    };

    const handleTabChange = (selectedTab) => {
        setTab(selectedTab);
        setCurrentPage(1); 
        fetchReceipts(); 
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);
    };

    const scrollToTop = () => {
        ListRef.current.scrollIntoView({ behavior: 'smooth' });
      };

      const handlePageClick = (page) => {
        setCurrentPage(page);
        scrollToTop();
    };
  
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            scrollToTop();
        }
    };
  
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            scrollToTop();
        }
    };
    
    const filteredReceipts = Array.isArray(receipts) 
    ? (tab === 'all' ? receipts : receipts.filter(req => req.status === tab)) 
    : [];

    console.log('Filtered Receipts:', filteredReceipts);
    console.log('Current Tab:', tab);

    const handleSelectChange = (event) => {
        handleTabChange(event.target.value);
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <FormContainer>
        <FormTitle>Receipt Management</FormTitle>
        <Tabs>
            <Tab active={tab === 'pending'} onClick={() => handleTabChange('pending')}>
            <p>Pending</p> <p>({counts.pendingRequests})</p>
            </Tab>
            <Tab active={tab === 'waiting'} onClick={() => handleTabChange('waiting')}>
            <p>Waiting</p> <p>({counts.waitingRequests})</p>
            </Tab>

            <select value={tab} onChange={handleSelectChange} style={{ marginLeft: '10px' }}>
                <option value="all">All ({counts.allRequests})</option>
                <option value="all">All Requests ({counts.allRequests})</option>
                <option value="approved">Approved ({counts.approvedRequests})</option>
                <option value="pending">Pending ({counts.pendingRequests})</option>
                <option value="waiting">Waiting ({counts.waitingRequests})</option>
                <option value="rejected">Rejected ({counts.rejectedRequests})</option>
            </select>
        </Tabs>
        <SearchInput
            type="text"
            placeholder="Search by username or phone"
            value={search}
            onChange={handleSearchChange}
        />
        <RequestList ref={ListRef}>
            {filteredReceipts.map(receipt => (
                <RequestItem key={receipt.id} onClick={() => handleSelectReceipt(receipt)}>
                    {receipt.username} - {<img src={`${baseURL}${receipt.path}`} alt='Receipt' width={'100px'} height={'100px'} />} - {receipt.status}
                </RequestItem>
            ))}
        </RequestList>
        {totalPages > 1 && (
            <PaginationWrapper>
            <Pagination>
                <PaginationButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous
                </PaginationButton>
                {Array.from({ length: totalPages }, (_, index) => (
                    <PageNumber
                        key={index + 1}
                        onClick={() => handlePageClick(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </PageNumber>
                ))}
                <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next
                </PaginationButton>
            </Pagination>
        </PaginationWrapper>
        )}
        {showDetails && selectedReceipt && (
            <>
            <ModalBackdrop onClick={handleCloseDetails} />
            <FormModelDiv>
            <ReceiptDetails
                        receipt={selectedReceipt}
                        onClose={handleCloseDetails}
                        refresh={fetchReceipts}
                    />
            </FormModelDiv>        
            </>        
        )}
        </FormContainer>
    );
};

export default ForeignTransferReceipts;

