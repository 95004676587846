import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { getValidToken } from '../utils/auth';
import styled from 'styled-components';
import NotificationComponent from './Notifications';
import SearchIcon from '@material-ui/icons/Search';
import { IoMdCloseCircle } from "react-icons/io";
import NotificationsIcon from '@material-ui/icons/Notifications';
import useLogout from '../utils/useLogout';
import { API_URL, baseURL } from '../config';
import Avatar from './Avatar';
import AddFundModel from './AddFundModel';
import { SkeletonWrapper } from './SkeletonWrapper';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../App';

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  //position: fixed;
  width: 100%;
  //z-index: 10;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }

  @media (max-width: 290px) {
    font-size: 1rem;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AuthorDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
`;

const NotificationContainer = styled.div`
  position: relative;
`;

const NotificationCounter = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: ${(props) => (props.hasNotifications ? '#ff0000' : 'transparent')};
  color: #ffffff;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 50%;
  cursor: pointer;
`;

const CustomListDiv = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.text};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  padding: 10px;
  width: 205px;
  overflow: auto;
`;

const AddFundModelDiv = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.text};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1100;
  padding: 10px;
  width: 100%;
  overflow: auto;
`;

const ButtonPlus = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  h4 {
    font-weight: bold;
    color: white;
  }
`;

const ShowNotificationDiv = styled.div`
  position: absolute;
  top: 50px;
  right: 0px; /* Add some padding from the right edge */
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.text};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1200;
  padding: 10px;
  width: 325px;
  max-width: 90vw; /* Make sure it doesn't exceed viewport width */
  overflow: auto;

  // @media (max-width: 768px) {
  //   width: 90%; /* Adjust width for smaller screens */
  //   right: 5%; /* Center it with some padding */
  // }

  // @media (max-width: 480px) {
  //   width: 95%; /* Further adjust for very small screens */
  //   right: 2.5%; /* Center it with some padding */
  // }
`;

const CustomMenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.text};
  &:hover {
    background-color: #f1f1f1;
    color: ${(props) => props.theme.colors.dark};
  }
`;



const Header = ({ handleSearch, toggleSearchInput, iconType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutUser = useLogout();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [addFundModelOpen, setAddFundModelOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);



  console.log('UserData in header', userData);
    
  useEffect(() => {
    const fetchNotifications = async () => {
      const token = await getValidToken(navigate, false); 
      if (token) {
        setIsAuthenticated(true);
        try {
          const response = await axios.get(`${API_URL}/notifications`, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            withCredentials: true
          });
          setNotifications(response.data.notifications);
        } catch (error) {
          console.error('Error fetching notifications:', error);
        } finally {
          setIsLoading(false); // Ensure loading state is cleared
        }
      } else {
        setIsLoading(false); // Ensure loading state is cleared if no token
      } 
    };

    fetchNotifications();
  }, [navigate]);
  

  useEffect(() => {
    const fetchData = async () => {
      const token = await getValidToken(navigate, false); // Use await here
      if (token) {
        setIsAuthenticated(true);
        try {
          const response = await axios.get(`${API_URL}/User`, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            withCredentials: true
          });
          setUserData(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error.response?.data || error.message);
        } finally {
          setIsLoading(false); // Ensure loading state is cleared
        }
      } else {
        setIsLoading(false); // Ensure loading state is cleared if no token
      }
    };

    fetchData();
  }, [navigate]);


  const handleSearchIconClick = () => { 
    setAddFundModelOpen(false);
    setMenuOpen(false);
    toggleSearchInput();
  };

  const handleAvatarClick = () => {
    setAddFundModelOpen(false);
    setMenuOpen(!menuOpen);
  };

  const handleOpenNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleAddFundModel = () => {
    setMenuOpen(false);
    setAddFundModelOpen(!addFundModelOpen);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleMenuClose();
  };

  const { i18n } = useTranslation();

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng); // Store selected language in localStorage
  };
  

  const handleLogout = () => {
    logoutUser();
    setIsAuthenticated(false);
  };

  // const markAsRead = async (id) => {
  //   setNotifications((prevNotifications) =>
  //     prevNotifications.map((notification) =>
  //       notification.id === id ? { ...notification, read: true } : notification
  //     )
  //   );
  
  //   const token = localStorage.getItem('jwtToken');
  //   try {
  //     await axios.put(`/notifications/${id}/read`, null, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //   } catch (error) {
  //     console.error('Error marking notification as read:', error);
  //   }
  // };

  const ThemeToggle = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);
    
    return (
      <button onClick={toggleTheme}>
        {theme === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'}
      </button>
    );
  };

  // Format wallet balance with commas
const formatWallet = (wallet) => {
  // Check if wallet is a valid number or string that can be converted to a number
  if (isNaN(parseFloat(wallet)) || !isFinite(wallet)) {
    return 'Invalid earnings';
  }

  // Convert earnings to number and ensure it's a valid number
  wallet = parseFloat(wallet);
  if (isNaN(wallet) || !isFinite(wallet)) {
    return 'Invalid wallet';
  }

  // Convert earnings to string with 2 decimal places
  let walletStr = wallet.toFixed(2);

  // Split wallet into whole and decimal parts
  const parts = walletStr.split('.');
  let wholePart = parts[0];
  const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

  // Add commas for thousands, millions, etc.
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  wholePart = wholePart.replace(regex, ',');

  // Return formatted earnings
  return wholePart + decimalPart;
};

  // Format earnings with custom function
  const formattedWalletBalance = formatWallet(userData?.wallet);

  const unreadCount = notifications.filter((notification) => !notification.read).length;

  return (
    <HeaderDiv>
      <LeftSection>
        {isAuthenticated ? (
          <>
            {isLoading ? (
              <AuthorDetailsDiv>
                <SkeletonWrapper style={{ height:'50px', width:'50px', borderRadius: '50%' }} />
                <AuthorInfo>
                  <SkeletonWrapper style={{ height:'20px', width:'100px' }} />
                  <SkeletonWrapper style={{ height:'15px', width:'85px' }} />
                </AuthorInfo>
              </AuthorDetailsDiv>
            ) : (
              <>
                <AuthorDetailsDiv onClick={handleAvatarClick}>
                  <Avatar
                    style={{ cursor: 'pointer' }}
                    username={userData?.username}
                    profilePicture={userData.profile_picture ? `${baseURL}${userData.profile_picture}` : null}
                    avatarColor={userData.avatar_color}
                  />
                </AuthorDetailsDiv>
                <AuthorInfo>
                  <h4>{userData.username}</h4>
                  <div style={{ display: 'flex', margin: '0', gap: '10px' }}>
                    <span>₦{formattedWalletBalance}</span>
                    <ButtonPlus onClick={handleAddFundModel}><h4>+</h4></ButtonPlus>
                  </div>
                </AuthorInfo>
              </>
            )}
            {addFundModelOpen && (
              <AddFundModelDiv>
                <AddFundModel toggleModal={handleAddFundModel} />
              </AddFundModelDiv>
            )}
            {menuOpen && (
              <CustomListDiv>
                <CustomMenuItem onClick={() => handleMenuItemClick('/user-details')}>Profile</CustomMenuItem>
                <CustomMenuItem onClick={() => handleMenuItemClick('/My-wallet')}>Wallet, N{formattedWalletBalance}</CustomMenuItem>
                <CustomMenuItem onClick={() => handleMenuItemClick('/')}>Home</CustomMenuItem>
                <CustomMenuItem onClick={() => handleMenuItemClick('/My-Videos')}>My Videos</CustomMenuItem> 
                <CustomMenuItem onClick={() => handleMenuItemClick('/vip/section/new')}>Vip Section New</CustomMenuItem>
                <CustomMenuItem onClick={() => handleMenuItemClick('/kasuwa/section')}>Kasuwa Section</CustomMenuItem>
               {/* <CustomMenuItem onClick={() => handleMenuItemClick('/purchase/post/ticket')}>Purchase Ticket</CustomMenuItem>*/}
                <CustomMenuItem onClick={() => handleMenuItemClick('/create/ad')}>Create Ads</CustomMenuItem>
                <CustomMenuItem onClick={() => handleMenuItemClick('/manage/ads')}>Manage Ads</CustomMenuItem>
                <CustomMenuItem onClick={() => handleMenuItemClick('/chat/lists')}>Chats</CustomMenuItem>
             {/*   <CustomMenuItem onClick={() => handleMenuItemClick('/lissafin/rayuwa')}>Lissafin Rayuwa</CustomMenuItem>*/}
                {userData.role_id === 2 && (
                  <CustomMenuItem onClick={() => handleMenuItemClick('/become/creator/form')}>Become Creator</CustomMenuItem>
                )}
                <CustomMenuItem onClick={() => handleLanguageChange('en')}>English</CustomMenuItem>
                <CustomMenuItem onClick={() => handleLanguageChange('ha')}>Hausa</CustomMenuItem>
                {(userData.role_id === 1 || userData.role_id === 3 || userData.role_id === 33 || userData.role_id === 7) && (
                  <>
                    <CustomMenuItem onClick={() => handleMenuItemClick('/upload')}>Upload a new post</CustomMenuItem>
                    <CustomMenuItem onClick={() => handleMenuItemClick('/earnings')}>Earnings</CustomMenuItem>
                    <CustomMenuItem onClick={() => handleMenuItemClick('/My-Posts')}>My Posts</CustomMenuItem>
                    <CustomMenuItem onClick={() => handleMenuItemClick('/Analytics')}>Analytics</CustomMenuItem>
                    <CustomMenuItem onClick={() => handleMenuItemClick('/search/post/ticket')}>Search Ticket</CustomMenuItem>
                   <CustomMenuItem onClick={() => handleMenuItemClick('/lissafin/rayuwa')}>Lissafin Rayuwa</CustomMenuItem>        
                   <CustomMenuItem onClick={() => handleMenuItemClick('/purchase/post/ticket')}>Purchase Ticket</CustomMenuItem>
                  </>
                )}
                <ThemeToggle />
                <CustomMenuItem onClick={() => handleMenuItemClick('/contactUs')}>Contact Us</CustomMenuItem>
                <CustomMenuItem onClick={handleLogout}>Logout</CustomMenuItem>
              </CustomListDiv>
            )}
          </>
        ) : (
          <>
            <Link to="/login" style={{ textDecoration: 'none', color: '#007bff', marginRight: '20px' }}>
              Login / Register
            </Link>
            <CustomMenuItem onClick={() => handleLanguageChange('en')}>English</CustomMenuItem>
            <CustomMenuItem onClick={() => handleLanguageChange('ha')}>Hausa</CustomMenuItem>
            {/* <ThemeToggle /> */}
          </>
        )}
      </LeftSection>
      <RightSection>
        <div style={{ position: 'relative' }}>
          {iconType === 'search' ? (
            <SearchIcon style={{ cursor: 'pointer', fontSize: '24px' }} onClick={handleSearchIconClick} />
          ) : (
            <IoMdCloseCircle style={{ cursor: 'pointer', fontSize: '24px' }} onClick={handleSearchIconClick} />
          )}
        </div>
        <NotificationContainer onClick={() => {navigate('/Notifications')}}>
          <NotificationsIcon style={{ cursor: 'pointer', fontSize: '24px' }} />
          {unreadCount > 0 && <NotificationCounter hasNotifications>{unreadCount}</NotificationCounter>}
          {/* {showNotifications && (
            <ShowNotificationDiv>
              <NotificationComponent
                notifications={notifications}
                markAsRead={markAsRead}
                onClose={handleOpenNotifications}
              />
            </ShowNotificationDiv>
          )} */}
        </NotificationContainer>
      </RightSection>
    </HeaderDiv>
  );
};


export default Header;
