import axios from 'axios';
import { API_URL } from '../config';
import { v4 as uuidv4 } from 'uuid';
import { getValidToken } from './auth';
import generateDeviceIdentifier from './deviceIdentifier';

const getIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    return response.data.ip;
  } catch (error) {
    console.error('Error getting IP address:', error);
    return null;
  }
};

const incrementViewCountForAd = async (viewType, componentId, navigate) => {
  try {
    const token = await getValidToken(navigate, false);
    const uniqueId = uuidv4();
    const deviceIdentifier = await generateDeviceIdentifier();
    const ipAddress = await getIpAddress();
    const apiUrl = token ? `${API_URL}/increment-ad-view` : `${API_URL}/public/increment-ad-view`;

    const requestOptions = {
      method: 'POST',
      url: apiUrl,
      headers: token ? { Authorization: `Bearer ${token}` } : undefined,
      withCredentials: true,
      data: { componentId, viewType, uniqueId, deviceIdentifier, ipAddress },
    };

    await axios(requestOptions);
    console.log('View incremented and session storage updated');
  } catch (error) {
    console.error('Error incrementing view count:', error);
  }
};

export default incrementViewCountForAd; 

