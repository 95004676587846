import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_URL, baseURL } from '../config';
import ShowStatusModel from './ShowStatusModel';
import styled, { keyframes } from 'styled-components';
import { getValidToken } from '../utils/auth';
import  Spinner  from './Spinner';
import TutorialVideoModel from './TutorialVideoModel';
import { TiWorld } from "react-icons/ti";

import { PlayAudioButton } from './PublicStyles';
import { BiMoviePlay } from "react-icons/bi";
import { FaCamera, FaUpload } from "react-icons/fa";
import { MdClose } from 'react-icons/md';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const HeaderSection = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const CloseDiv = styled.div`
  background-color: red;
  border-radius: 10px;
  position: absolute;
  font-weight: 800;
  right: 0;
  margin-left: 10px;
  padding: 1px;
  cursor: pointer;
`;

const Card = styled.div`
  background: ${(props) => props.theme.colors.background2};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 20px;
  text-align: center;
  position: relative;
`;

const Title = styled.h2`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  color: ${(props) => props.theme.colors.text};
`;

const Note = styled.p`
  color: red;
`;

const BankInfo = styled.p`
  margin: 10px 0;
  color: ${(props) => props.theme.colors.text};
`;

const Hr = styled.hr`
  margin: 40px 0;
  color: ${(props) => props.theme.colors.text};
`;

const FileInput = styled.input`
  display: none;
`;

const Label = styled.label`
  display: inline-block;
  background: #007bff;
  color: ${(props) => props.theme.colors.text};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: #0056b3;
  }
`;

const CopyToClipBoardButton = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  margin-top: 10px;
`;

// Container for previewing the image
const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 20px 0;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Image preview styling
const PreviewImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
  object-fit: contain;
`;

// Message to indicate status (pending, approved, rejected)
const StatusMessage = styled.p`
  color: ${props => props.status === 'pending' ? '#FFA500' : props.status === 'rejected' ? '#FF0000' : '#008000'};
  font-weight: bold;
  margin-bottom: 10px;
`;

const MessageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;
  padding: 5px;

  p {
    color: red;
  }
`;

// Button styling
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding: 10px 20px;
  margin: 10px 5px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

// Close button styling
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: #ff0000;
  }
`;

const pulseAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
`;

const AudioIndicator = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${props => props.theme.colors.danger};
  border-radius: 50%;
  display: ${props => (props.showVideoModal || props.status ? 'block' : 'none')};
  animation: ${pulseAnimation} 1s infinite alternate;

  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
  }

  @media (max-width: 480px) {
    width: 10px;
    height: 10px;
  }
`;

const AddFundModel = ({ toggleModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userWalletAccounts, setUserWalletAccounts] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [receipt, setReceipt] = useState(null);
  const [receiptUrl, setReceiptUrl] = useState(null);
  const [preview, setPreview] = useState(null);
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('')
  const [receiptStatus, setReceiptStatus] = useState(null);

  const { t } = useTranslation();
  
  const [showVideoModal, setShowVideoModal] = useState(false);

  
  const handleShowModal = () => {
    setShowVideoModal(!showVideoModal);
  };

  const handleClose = () => {
    toggleModal();
  };

  const navigate = useNavigate();

  const fetchUserWalletAccounts = useCallback(async () => {
    const token = await getValidToken(navigate, true);
    try {
      const response = await axios.get(`${API_URL}/User/Wallet/Accounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setUserWalletAccounts(response.data.accounts);
      } else {
        setErrorMessage(response.data.error || 'An error occurred.');
        setShowModal(true);
        setTimeout(() => {
          setErrorMessage('');
          setShowModal(false);  
          }, 4000);
      }
    } catch (error) {
        setErrorMessage(error.response?.data?.message || 'An error occurred.');
        setShowModal(true);

        setTimeout(() => {
        setErrorMessage('');
        setShowModal(false);  
        }, 4000);
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  const fetchReceiptStatus = useCallback(async () => {
    const token = await getValidToken(navigate, true);
    
    if (!token) {
      setErrorMessage('Authentication failed.');
      setShowModal(true);
      setTimeout(() => {
        setErrorMessage('');
        setShowModal(false);
      }, 4000);
      return;
    }
    
    try {
      const response = await axios.get(`${API_URL}/receipts/status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        const { status, receiptPath, message, code } = response.data;
        
        setReceiptStatus(status);
        
        if (status === 'pending' || status === 'approved' || status === 'rejected') {
          setPreview(receiptPath);
          setReceiptUrl(receiptPath);
          setCode(code);
        }
        
        if (status === 'rejected') {
          setMessage(message);
        }
        
      } else {
        throw new Error(response.data.error || 'An error occurred.');
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'An error occurred.');
      setShowModal(true);
      setTimeout(() => {
        setErrorMessage('');
        setShowModal(false);
      }, 4000);
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);  

  useEffect(() => {
    fetchUserWalletAccounts();
    fetchReceiptStatus();
  }, [fetchUserWalletAccounts, fetchReceiptStatus]);

  const handleCloseModal = () => {
    setShowModal(false);
    toggleModal();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setReceipt(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleRetake = () => {
    setReceipt(null);
    setPreview(null);
  };

  const handleSubmit = async () => {
    if (!receipt) return;
  
    const formData = new FormData();
    formData.append('receipt', receipt);
  
    try {
      const token = await getValidToken(navigate, true);
      await axios.post(`${API_URL}/upload-receipt`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
  
      alert('Receipt uploaded successfully!');
      fetchReceiptStatus();
    } catch (error) {
      setErrorMessage('Failed to upload receipt.');
      setShowModal(true);
      setTimeout(() => {
        setErrorMessage('');
        setShowModal(false);  
        }, 4000);
    }
  };

  const copyToClipboard = async (text) => {
  try {
    // Check if the clipboard API is available
    if (navigator?.clipboard?.writeText) {
      // Ensure text is not undefined or null
      if (text) {
        await navigator.clipboard.writeText(text);
        alert('Copied to clipboard!');
      } else {
        alert('No text to copy.');
      }
    } else {
      // Fallback for older browsers or unsupported platforms
      fallbackCopyToClipboard(text);
    }
  } catch (error) {
    console.error('Error copying text to clipboard:', error);
    alert('Failed to copy to clipboard.');
  }
};

// Fallback function to copy text to clipboard using document.execCommand
const fallbackCopyToClipboard = (text) => {
  if (!text) {
    alert('No text to copy.');
    return;
  }

  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';  // Prevent scrolling to bottom
  textArea.style.left = '-9999px'; // Move off-screen
  document.body.appendChild(textArea);
  textArea.select();
  try {
    const successful = document.execCommand('copy');
    if (successful) {
      alert('Copied to clipboard!');
    } else {
      alert('Failed to copy to clipboard.');
    }
  } catch (error) {
    console.error('Fallback: Error copying text to clipboard:', error);
    alert('Failed to copy to clipboard.');
  } finally {
    document.body.removeChild(textArea);
  }
};


  // generate and download Link
  const fetchDownloadLink = async (fileUrl) => {
    const token = await getValidToken(navigate, true); 
    try {
      const response = await axios.get(`${API_URL}/download-receipt`, {
        params: { fileUrl },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        return response.data.downloadUrl;
      } else {
        throw new Error('Failed to fetch download link');
      }
    } catch (error) {
      console.error('Error fetching download link:', error);
      return null;
    }
  };
  
  const handleDownload = async (fileUrl) => {
    try {
      const downloadLink = await fetchDownloadLink(fileUrl);
      console.log('signed download url:', downloadLink);
      if (downloadLink) {
        // Create an anchor element and set the href to the download link
        const a = document.createElement('a');
        a.href = downloadLink;
        a.download = fileUrl.split('/').pop(); // Set the file name for download
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  };
  
  
  if (isLoading) {
    return (
      <Container>
        <Card>
        <Spinner />
        </Card>
      </Container>
    )
  }
  

  return (
    <>
      {/* <Overlay onClick={handleCloseModal} /> */}
      <Container>
      {userWalletAccounts ? (
          <>
          {showModal && <ShowStatusModel message={errorMessage} onClose={handleCloseModal} />}

          {!showModal && (
          <>
          <HeaderSection>
            <div>
          <PlayAudioButton onClick={handleShowModal} style={{ marginBottom: '20px' }}>
          {t('play video')} <AudioIndicator showVideoModal={showVideoModal}></AudioIndicator> 
          <BiMoviePlay style={{ color: 'red', fontSize: '24px' }} />
          </PlayAudioButton>
          </div>
          <CloseDiv onClick={handleClose}>
            <MdClose size={24} color='#fff'/>
          </CloseDiv>
          </HeaderSection>
        
          {showVideoModal && (
            <TutorialVideoModel
              videoId="UXFhm_V_cUg"
              onClose={handleShowModal}
            />
          )}
          
          </>
          )}
            <Card>
              <Title>Wema Bank</Title>
              <BankInfo>Account Number: <b>{userWalletAccounts?.wema_bank_account}</b></BankInfo>
              <BankInfo>Name: Arewa Kallo - {userWalletAccounts.first_name}</BankInfo>
              <Note>Note: Automated bank transfer attracts additional charges of 1.5% only.</Note>
              <CopyToClipBoardButton onClick={() => copyToClipboard(userWalletAccounts?.wema_bank_account)}>Copy Account Number</CopyToClipBoardButton>
            </Card>

            <Card>
              <Title>Sterling Bank</Title>
              <BankInfo>Account Number: <b>{userWalletAccounts?.sterling_bank_account}</b></BankInfo>
              <BankInfo>Name: Arewa Kallo - {userWalletAccounts.first_name}</BankInfo>
              <Note>Note: Automated bank transfer attracts additional charges of 1.5% only.</Note>
              <CopyToClipBoardButton onClick={() => copyToClipboard(userWalletAccounts?.sterling_bank_account)}>Copy Account Number</CopyToClipBoardButton>
            </Card>
          </>
        ) : (
          <Title>No Available Accounts Found, You can send through Admin Ac.No Below</Title>
        )}


        <Hr />

        <Title>Foreign Transfer <TiWorld size={34} color={'black'} /></Title>
        
        <Card>
              <Title>Nita Transfer Details</Title>
              <BankInfo>Lambar Waya: <b>+2348030696401</b></BankInfo>
              <BankInfo>Suna: <b>Arewa Kallo</b></BankInfo>
              <BankInfo>Adireshi: <b>Kano Nigeria</b></BankInfo>
              <Note>Note: Nita transfer attracts additional charges of 10% only.</Note>
              <CopyToClipBoardButton onClick={() => copyToClipboard(+2348030696401)}>Copy The Number</CopyToClipBoardButton>
        </Card>

        {!preview && (
          <p>If you live abroad, click the input below to snap a picture of the transaction slip.</p>
        )}
        
        {preview && (
        <PreviewContainer>
          <PreviewImage src={`${baseURL}${preview}`} alt="Receipt Preview" />
          {code && <div><p><b>{code}</b></p></div>}
          {message && receiptStatus === 'rejected' && (
          <MessageDiv>
          <b><p style={{color: 'red'}}>{message}</p></b>
          </MessageDiv>
          )}
          {receiptStatus === 'pending' && 
          <>
          <StatusMessage status={receiptStatus}>
            Receipt is pending for review. 
          </StatusMessage>
          <AudioIndicator status={receiptStatus}></AudioIndicator>
          <Button onClick={() => handleDownload(receiptUrl)}>Download Receipt</Button>
          </>
          }
          {receiptStatus !== 'pending' && <Button onClick={handleRetake}>Retake <FaCamera size={24} color='black' /></Button>}
          <CloseButton onClick={handleCloseModal}><MdClose /></CloseButton>
        </PreviewContainer>
      )}

      {!preview && (
        <>
          <Label htmlFor="fileInput">Upload Receipt <FaCamera size={24} color='black' /></Label>
          <FileInput id="fileInput" type="file" accept="image/*" capture="environment" onChange={handleFileChange} />
        </>
      )}

      {preview && receiptStatus !== 'pending' && <Button onClick={handleSubmit}>Submit <FaUpload size={24} color={'black'} /></Button>}

        <Hr />

        <Title>Manual Bank Transfer</Title>
        <Card>
          <Title>Opay</Title>
          <BankInfo>Account Number: <b>8030696401</b></BankInfo>
          <BankInfo>Name: <b>Usama</b></BankInfo>
          <Note>Note: Contact Admin before and after sending.</Note>
        </Card>

      </Container>
    </>
  );
};

export default AddFundModel;
